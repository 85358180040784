<template>
  <div>
    <div class="container">
      <el-container>
        <transition name="el-zoom-in-center">
          <i
            v-show="!showTree"
            class="el-icon-s-unfold"
            @click="showTree=true"
            style="float:left;cursor: pointer;color:#333;font-size: 24px;margin-right: 16px;"
          ></i>
        </transition>
        <transition name="el-zoom-in-top">
          <el-aside width="240px" v-show="showTree">
            <i
              class="el-icon-s-fold"
              @click="showTree=false"
              style="float:right;cursor: pointer;color:#333;font-size: 30px;margin-right: 8px;"
            ></i>
            <type-tree typeKind="ExpenseType" @currentTypeArray="currentTypeArray"></type-tree>
          </el-aside>
        </transition>

        <el-main>
          <el-table :data="data" stripe border @row-dblclick="editType">
            <el-table-column label="序号" type="index" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column label="排序" prop="code" show-overflow-tooltip></el-table-column>
            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </div>
    <div>
      <edit-type typeKind="ExpenseType" :item="expenseTypeItem" @success="getData"></edit-type>
    </div>
  </div>
</template>

<script>
import TypeTree from "@/components/BaseType/TypeTree";
import EditType from "@/components/BaseType/TypeItem";
export default {
  components: {
    TypeTree,
    EditType
  },
  data() {
    return {
      showTree: true,
      filter: [],
      data: [],
      expenseTypeItem: {}
    };
  },
  watch: {
    "$store.state.baseinfo.ExpenseType": {
      handler: function(v) {
        this.getData();
      }
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let list = [];
      let isFilter = this.filter.length > 0;
      this.$store.state.baseinfo.ExpenseType.forEach(item => {
        if (isFilter) {
          if (this.filter.indexOf(item.id) >= 0) {
            list.push({
              ...item
            });
          }
        } else {
          list.push({
            ...item
          });
        }
      });
      this.data = list.sort((a, b) => {
        var x = a.code || "";
        var y = b.code || "";
        return x.localeCompare(y);
      });
    },
    currentTypeArray(array) {
      this.filter = array;
      this.getData();
    },
    editType(row) {
      this.expenseTypeItem = {
        id: row.id
      };
    }
  }
};
</script>

<style>
</style>